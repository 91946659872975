import { ComponentProps, forwardRef, useMemo } from 'react';

import classNames from 'classnames';
import { isNil } from 'lodash';

import styles from './styles.module.css';

type Props = Pick<ComponentProps<'input'>, 'onKeyDown'> & {
  /**
   * The name of label
   */
  label?: string;
  /**
   * autofocus
   */
  autoFocus?: boolean;
  /**
   * The name of input
   */
  name: string;
  /**
   * The type of input (text, email, password, etc)
   */
  type?: string;
  /**
   * Disable the input
   */
  disabled?: boolean;
  /**
   * Additional class name
   */
  className?: string;
  /**
   * Is input center
   */
  isCenter?: boolean;
  /**
   * The value of placeholder
   */
  placeholder?: string | null;
  /**
   * Error message as string
   */
  error?: boolean | string;
  /**
   * Input value as string;
   */
  value: string;
  /**
   * Trailing icon as ReactNode
   */
  TrailingIcon?: React.ReactNode;
  /**
   * Leading icon as ReactNode
   */
  LeadingIcon?: React.ReactNode;

  /**
   * Is input required
   */
  required?: boolean;

  /**
   * size
   */
  size?: 'medium' | 'large';

  autoComplete?:
    | boolean
    | 'off'
    | 'family-name'
    | 'given-name'
    | 'email'
    | 'on';

  /**
   * onBlur function fired when input is blurred
   * @param event
   */
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  /**
   * onFocus function fired when input is focussed
   * @param event
   */
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  /**
   * onChange function fired when input is changed
   * @param event
   */
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  /**
   * Is input dark
   * @default false
   */
  isDark?: boolean;
  readOnly?: boolean;
};

const Input = forwardRef<HTMLInputElement, Props>(
  (
    {
      label,
      size = 'medium',
      autoFocus = false,
      required = false,
      isCenter,
      name,
      autoComplete = true,
      type = 'text',
      placeholder,
      disabled = false,
      readOnly = false,
      value = '',
      TrailingIcon = null,
      LeadingIcon = null,
      onBlur,
      onFocus,
      error,
      onChange,
      isDark,
      className,
      ...inputProps
    },
    ref,
  ) => {
    const autoCompleteValue = useMemo(() => {
      if (autoComplete === undefined || autoComplete === null) return 'off';
      if (typeof autoComplete === 'boolean') return autoComplete ? 'on' : 'off';
      if (typeof autoComplete === 'string') return autoComplete;
      return 'off';
    }, [autoComplete]);

    return (
      <div
        className={classNames([
          styles.container,
          {
            [styles.dark]: isDark,
            [styles.center]: isCenter,
            [styles.hasError]: error,
            [styles.medium]: size === 'medium',
            [styles.large]: size === 'large',
          },
          className ?? '',
        ])}
      >
        {label && (
          <label htmlFor={name} className={styles.label}>
            {label}
            {required && <span className={styles.required}>*</span>}
          </label>
        )}
        <div
          className={classNames(styles.wrapper, {
            [styles.disabled]: disabled,
            [styles.readOnly]: readOnly,
          })}
        >
          {LeadingIcon && <div className={styles.icon}>{LeadingIcon}</div>}

          <input
            autoCorrect="false"
            onBlur={onBlur}
            onFocus={onFocus}
            id={name}
            name={name}
            value={value}
            ref={ref}
            type={type}
            autoComplete={autoCompleteValue}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={autoFocus}
            disabled={disabled}
            readOnly={readOnly}
            onChange={onChange}
            aria-invalid={!!error}
            aria-disabled={disabled}
            placeholder={isNil(placeholder) ? '' : placeholder}
            aria-placeholder={isNil(placeholder) ? '' : placeholder}
            {...inputProps}
          />
          {TrailingIcon && <div className={styles.icon}>{TrailingIcon}</div>}
        </div>
        {typeof error === 'string' && (
          <span className={styles.error}>{error}</span>
        )}
      </div>
    );
  },
);

Input.displayName = 'Input';

export default Input;
