import { useCallback, useContext, useMemo } from 'react';

import getSymbolFromCurrency from 'currency-symbol-map';
import { format } from 'date-fns';
import { t } from 'i18next';
import { ClipLoader } from 'react-spinners';

import { UserContext } from '@/contexts/UserContext';
import useGetInvoiceUrl from '@/hooks/paddle/useGetInvoiceUrl';
import { useGetInvoices } from '@/hooks/paddle/useGetInvoices';
import { useIntersectionObserver } from '@/hooks/utils/useIntersectionObserver';
import { TTransactions } from '@/types/invoices';

import InvoiceListItemSkeleton from '@/components/skeleton/InvoiceListItemSkeleton';
import InvoiceListSkeleton from '@/components/skeleton/InvoiceListSkeleton';

import styles from './styles.module.css';

type InvoiceItemProps = {
  invoice: TTransactions;
};

function InvoiceItem({ invoice }: InvoiceItemProps) {
  const invoicAmountElement = useMemo(() => {
    const currencySymbol = getSymbolFromCurrency(invoice.currency);
    if (!invoice.discount) {
      return <span>{`${invoice.totalAmount}${currencySymbol}`}</span>;
    } else {
      let amount = invoice.baseAmount;
      if (invoice.tax) amount += invoice.tax;
      return (
        <span>
          <s>{`${amount}${currencySymbol}`}</s>{' '}
          <span>{`${invoice.totalAmount}${currencySymbol}`}</span>
        </span>
      );
    }
  }, [
    invoice.baseAmount,
    invoice.currency,
    invoice.discount,
    invoice.tax,
    invoice.totalAmount,
  ]);

  const { data, isLoading, isFetching } = useGetInvoiceUrl({
    invoiceId: invoice.id,
    subscriptionId: invoice.subscriptionId,
    enabled: !!invoice.invoiceNumber,
  });

  return (
    <div className={styles.invoiceListItem}>
      <span>
        {invoice.billedAt && format(invoice.billedAt, 'MMMM dd, yyyy')}
      </span>
      <span>
        {invoice.productName} ({invoice.productType.toLocaleLowerCase()})
      </span>
      {invoicAmountElement}
      <span>
        {isLoading && isFetching ? (
          <ClipLoader size={16} />
        ) : data ? (
          <a href={data}>
            {t('common:profileModal.billingInfo.invoice.actions.download')}
          </a>
        ) : (
          invoice.status
        )}
      </span>
    </div>
  );
}

export default function InvoiceList() {
  const { workspace } = useContext(UserContext);

  const { data, fetchNextPage, hasNextPage, isLoading, isFetchingNextPage } =
    useGetInvoices({
      enabled: !!workspace?.id,
    });

  const currentPage = useMemo(() => {
    if (data?.pages?.length) return data?.pages?.[data?.pages?.length - 1];
  }, [data?.pages]);

  const invoices = useMemo(
    () => data?.pages.flatMap((value) => value?.transactions ?? []) ?? [],
    [data],
  );

  const fetchOnScroll = useCallback(
    (entry: IntersectionObserverEntry[]) => {
      if (entry?.[0]?.isIntersecting) fetchNextPage?.();
    },
    [fetchNextPage],
  );
  const { setTarget } = useIntersectionObserver(fetchOnScroll);

  return (
    <div className={styles.invoiceList}>
      {!invoices?.length && isLoading ? (
        <InvoiceListSkeleton />
      ) : (
        !invoices.length && (
          <span className={styles.noInvoices}>
            {t('common:profileModal.billingInfo.invoice.noInvoices')}
          </span>
        )
      )}
      {!!invoices.length &&
        invoices.map((invoice) => (
          <InvoiceItem key={invoice.id} invoice={invoice} />
        ))}
      {isFetchingNextPage && <InvoiceListItemSkeleton />}
      {hasNextPage && (
        <div
          key={`loader_${currentPage?.nextId}`}
          ref={setTarget}
          className={styles.loadMore}
        ></div>
      )}
    </div>
  );
}
