import { useCallback } from 'react';

import {
  IconAlertCircleFilled,
  IconCircleCheckFilled,
  IconCircleXFilled,
  IconInfoCircle,
  IconX,
} from '@tabler/icons-react';
import classNames from 'classnames';
import { noop } from 'lodash';

import styles from './styles.module.css';

type Props = {
  children: JSX.Element | string;
  variant?: 'error' | 'success' | 'alert' | 'info';
  hasIcon?: boolean;
  customIcon?: React.ReactNode;
  onDismiss?: typeof noop;
};

export default function BoxMessage({
  children,
  variant = 'info',
  customIcon,
  hasIcon = true,
  onDismiss,
}: Props) {
  const renderIcon = useCallback(() => {
    const classNamesProp = classNames(styles.icon, styles[variant]);
    if (customIcon) return customIcon;
    switch (variant) {
      case 'success':
        return <IconCircleCheckFilled className={classNamesProp} />;
      case 'alert':
        return <IconAlertCircleFilled className={classNamesProp} />;
      case 'error':
        return <IconCircleXFilled className={classNamesProp} />;
      case 'info':
        return <IconInfoCircle className={classNamesProp} />;
    }
  }, [variant, customIcon]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        {hasIcon && <div className={styles.icon}>{renderIcon()}</div>}
        <div className={styles.message}>{children}</div>
      </div>
      {onDismiss && <IconX className={styles.close} onClick={onDismiss} />}
    </div>
  );
}
