import { useContext } from 'react';

import { applyActionCode, getAuth } from '@firebase/auth';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { UserContext } from '@/contexts/UserContext';

import useUpdateAccount from '../account/useUpdateAccount';

const KEY = 'CHANGE_OR_RECOVER_EMAIL';
export default function useChangeUserEmail() {
  const auth = getAuth();
  const queryClient = useQueryClient();
  const logoutFn = async () => {
    await auth?.signOut();
    queryClient.clear();
    clearLocalStorage();
  };
  const { isLoggedIn, clearLocalStorage } = useContext(UserContext);

  const { mutate } = useUpdateAccount();

  return useMutation(
    [KEY],
    async ({ newEmail: _, oobCode }: { newEmail: string; oobCode: string }) => {
      if (isLoggedIn && auth.currentUser) await applyActionCode(auth, oobCode);
    },
    {
      onSuccess: async (_data, { newEmail }) => {
        mutate({ email: newEmail });
        logoutFn();
      },
    },
  );
}
