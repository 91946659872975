import {
  ComponentProps,
  ForwardRefExoticComponent,
  RefAttributes,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';

import { FirebaseError } from '@firebase/app';
import { t } from 'i18next';

import BoxMessage from '@/components/BoxMessage';
import Button from '@/components/Button';
import CheckYourInbox from '@/components/CheckInbox';

import Modal from '../Modal';
import EmailStage from './EmailStage';
import PasswordStage from './PasswordStage';
import styles from './styles.module.css';
import { ChangeEmailStageProps, ChangeEmailStageRefProps } from './type';
type ModalChangeEmailProps = ComponentProps<typeof Modal>;

type StageComponentType = ForwardRefExoticComponent<
  ChangeEmailStageProps & RefAttributes<ChangeEmailStageRefProps<string>>
>;

export default function ModalChangeEmail({
  isOpen,
  onClose,
  modalKey,
}: ModalChangeEmailProps) {
  const [stage, setStage] = useState<'email' | 'password' | 'completed'>(
    'password',
  );

  const stageRef = useRef<ChangeEmailStageRefProps<string>>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const [error, setError] = useState<Error | FirebaseError>();

  const onSubmitting = useCallback(() => {
    setIsSubmitting(true);
    setError(undefined);
  }, []);

  const onPasswordSubmitSuccess = useCallback(() => {
    setStage((oldStage) => {
      switch (oldStage) {
        case 'password':
          return 'email';
        case 'email':
          return 'completed';
        default:
          throw new Error('Invalid stage');
      }
    });
    setIsSubmitting(false);
  }, []);

  const Component = useMemo<StageComponentType | null>(() => {
    switch (stage) {
      case 'password':
        return PasswordStage;
      case 'email':
        return EmailStage;
      default:
        return null;
    }
  }, [stage]);

  const isCompleted = stage === 'completed';

  const onSubmitError = useCallback((error: Error) => {
    setError(error);
    setIsSubmitting(false);
  }, []);

  const errorMessage = useMemo(() => {
    if (!error) return undefined;
    if (error instanceof FirebaseError) {
      return t(`errors:firebase.${error.code}`);
    } else {
      return error?.toString();
    }
  }, [error]);

  return (
    <Modal
      title={
        !isCompleted
          ? t(
              `common:modals.changeEmail.${stage === 'password' ? 'password' : 'email'}Stage.title`,
            )
          : ''
      }
      modalKey={modalKey}
      enableClose
      isOpen={isOpen}
      onClose={onClose}
      footer={
        !isCompleted && (
          <>
            <Button
              label={t(
                `common:modals.changeEmail.${stage === 'password' ? 'password' : 'email'}Stage.actionLabel`,
              )}
              size="medium"
              isLoading={isSubmitting}
              disabled={isSubmitting}
              onClick={() => stageRef.current?.submit?.()}
            />
            <Button
              label={t('common:cancel')}
              size="medium"
              disabled={isSubmitting}
              variant="ghost"
              onClick={onClose}
            />
          </>
        )
      }
    >
      <div className={styles.modalContainer}>
        {error && errorMessage && (
          <BoxMessage variant="error">{errorMessage}</BoxMessage>
        )}
        {!isCompleted && Component && (
          <Component
            ref={stageRef}
            onSubmitting={onSubmitting}
            onSubmitError={onSubmitError}
            onSubmitSuccess={onPasswordSubmitSuccess}
          />
        )}
        {isCompleted && (
          <CheckYourInbox
            title={t('common:modals.changeEmail.completedStage.title')}
            message={t('common:modals.changeEmail.completedStage.message')}
          />
        )}
      </div>
    </Modal>
  );
}
