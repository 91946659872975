import { useCallback } from 'react';

import { useQuery } from '@tanstack/react-query';

import { http } from '@/services/api';
import { BaseResponse } from '@/types/base-responses';

export type TValidationeEmail = {
  status: string;
  email: string;
  result: boolean;
};

// export default function useValidateEmail() {
//   const [isValidating, setIsValidating] = useState<boolean>(false);
//   const [error, setError] = useState<string | null>(null);

//   const validate = useCallback((email: string) => {
//     setIsValidating(true);
//     return http
//       .get<BaseResponse<TValidationeEmail>>(`/accounts/validate-email`, {
//         params: { email },
//       })
//       .then(
//         ({
//           data: {
//             data: { status, email, result },
//           },
//         }) => {
//           setError(null);
//           return {
//             status,
//             email,
//             result,
//           };
//         },
//       )
//       .catch((error) => {
//         setError(error.message);
//         return {
//           status: 'error',
//           email,
//           result: false,
//         };
//       })
//       .finally(() => setIsValidating(false));
//   }, []);

//   return { validate, isValidating, error, isError: !!error };
// }

export const VALIDATION_EMAIL_KEY = 'validate-email';
export default function useValidateEmail({ email }: { email: string }) {
  const { data, isStale, refetch, ...others } = useQuery(
    [VALIDATION_EMAIL_KEY, email],
    async ({ queryKey: [_, email] }) => {
      console.log('email', email);
      return http
        .get<BaseResponse<TValidationeEmail>>(`/accounts/validate-email`, {
          params: { email },
        })
        .then(({ data: { data } }) => data)
        .catch(
          (_error) =>
            ({
              status: 'error',
              email,
              result: false,
            }) as TValidationeEmail,
        );
    },
    { enabled: false },
  );

  const validate = useCallback(async () => {
    if (!isStale) return data;
    return refetch().then(({ data }) => data);
  }, [data, isStale, refetch]);
  return { data, validate, isStale, ...others };
}
