import {
  PropsWithChildren,
  forwardRef,
  isValidElement,
  useEffect,
} from 'react';

import { Dialog } from '@headlessui/react';
import { IconX } from '@tabler/icons-react';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { noop } from 'lodash';
import { useHotkeysContext } from 'react-hotkeys-hook';

import { GLOBAL_SCOPE } from '@/types/constants';

import styles from './styles.module.css';
import Button from '../../Button';

export type ModalProps = PropsWithChildren<{
  modalKey: string;
  title?: string | React.ReactNode;
  isOpen: boolean;
  onClose?: () => void;
  footer?: React.ReactNode;
  titleClassName?: string;
  panelClassName?: string[];
  bodyClassName?: string;
  overflowShouldPreventClose?: boolean;
  enableClose?: boolean;
}>;

const mathRandom = Math.random();

const Modal = forwardRef<HTMLDivElement, ModalProps>(
  (
    {
      title,
      modalKey,
      titleClassName = '',
      isOpen,
      onClose,
      children,
      footer,
      panelClassName = [],
      bodyClassName = '',
      enableClose,
      overflowShouldPreventClose = false,
    },
    ref,
  ) => {
    const { disableScope, enableScope } = useHotkeysContext();
    useEffect(() => {
      if (isOpen) disableScope(GLOBAL_SCOPE);
      else enableScope(GLOBAL_SCOPE);
      return () => enableScope(GLOBAL_SCOPE);
    }, [isOpen, disableScope, enableScope]);

    return (
      <AnimatePresence>
        {isOpen && (
          <Dialog
            key={modalKey ?? String(mathRandom)}
            className={styles.container}
            static
            open={isOpen}
            ref={ref}
            as={motion.div}
            transition={{ ease: 'easeOut', duration: 0.2 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClose={onClose ? onClose : noop}
          >
            <div
              className={classNames(styles.overlay, {
                [styles.overlayPreventClose]: overflowShouldPreventClose,
              })}
            />
            <Dialog.Panel
              ref={ref}
              as={motion.div}
              transition={{ ease: 'easeOut', duration: 0.2, delay: 0.1 }}
              initial={{ opacity: 0, scale: 0.97 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.97 }}
              className={classNames(styles.panel, ...panelClassName)}
            >
              {(title || enableClose) && (
                <div
                  className={classNames(styles.header, {
                    [styles.noMarginBottom]: !title,
                  })}
                >
                  <Dialog.Title
                    as={isValidElement(title) ? 'div' : 'h2'}
                    className={classNames(styles.title, titleClassName)}
                  >
                    {title}
                  </Dialog.Title>
                  {onClose && (
                    <Button
                      icon={IconX}
                      variant="ghost"
                      size="medium"
                      onClick={onClose}
                    />
                  )}
                </div>
              )}
              <div className={classNames(styles.body, bodyClassName)}>
                {children}
              </div>
              {footer && <div className={styles.footer}>{footer}</div>}
            </Dialog.Panel>
          </Dialog>
        )}
      </AnimatePresence>
    );
  },
);

Modal.displayName = 'Modal';

export default Modal;
