import { useEffect, useMemo, useState } from 'react';

import { IconCheck } from '@tabler/icons-react';
import { t } from 'i18next';

import { getCssVariable } from '@/services/helpers';

import styles from './styles.module.css';
type Props = {
  currentPassword?: string;
  onValidationChanged?: (isValid: boolean) => void;
};

type ItemProps = {
  valid: boolean;
  text?: string;
};

const successColor = getCssVariable('--bg-success');

const RequirementsItem = ({ valid, text }: ItemProps) => {
  return (
    <div>
      <span>{valid && <IconCheck color={successColor} size={16} />}</span>
      {text}
    </div>
  );
};

const PasswordRequirements = ({
  currentPassword,
  onValidationChanged,
}: Props) => {
  const [allValid, setAllValid] = useState<boolean>(false);

  const [hasUppercase, hasLower, hasNumeric, rightLength] = useMemo(
    () =>
      currentPassword
        ? [
            /[A-Z]/.test(currentPassword),
            /[a-z]/.test(currentPassword),
            /[0-9]/.test(currentPassword),
            currentPassword.length >= 8,
          ]
        : [false, false, false, false],
    [currentPassword],
  );

  useEffect(() => {
    if (!currentPassword) return;
    const allValidCheck = hasUppercase && hasLower && hasNumeric && rightLength;
    if (allValid !== allValidCheck)
      setAllValid(hasUppercase && hasLower && hasNumeric && rightLength);
  }, [
    allValid,
    currentPassword,
    hasLower,
    hasNumeric,
    hasUppercase,
    rightLength,
  ]);

  useEffect(() => {
    onValidationChanged?.(allValid);
  }, [allValid, onValidationChanged]);

  return (
    <div className={styles.requirements}>
      <h4>{t('forms:resetPassword.requirements.title')}</h4>
      <div className={styles.requirementsList}>
        <RequirementsItem
          text={t('forms:resetPassword.requirements.upper')}
          valid={hasUppercase}
        />
        <RequirementsItem
          text={t('forms:resetPassword.requirements.lower')}
          valid={hasLower}
        />
        <RequirementsItem
          text={t('forms:resetPassword.requirements.numeric')}
          valid={hasNumeric}
        />
        <RequirementsItem
          text={t('forms:resetPassword.requirements.length')}
          valid={rightLength}
        />
      </div>
    </div>
  );
};
PasswordRequirements.displayName = 'PasswordRequirements';
export default PasswordRequirements;
