import { ComponentProps, forwardRef } from 'react';

import type { IconProps } from '@tabler/icons-react';
import classNames from 'classnames';
import { Link, type LinkProps } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';

import {
  cssVariablesByType,
  getCssVariable,
  normalizeLinkProps,
} from '@/services/helpers';

import styles from './styles.module.css';

type Props = ComponentProps<'button'> & {
  icon?: React.ComponentType<IconProps>;
  iconProps?: IconProps;
  label?: React.ReactNode;
  active?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  link?: LinkProps | string;
  size?: 'small' | 'medium' | 'large';
  variant?: 'default' | 'outline' | 'ghost' | 'destructive' | 'link';
  className?: string;
};

const Button = forwardRef<HTMLButtonElement, Props>(
  (
    {
      icon,
      iconProps,
      label,
      active,
      disabled,
      isLoading,
      onClick,
      link,
      size = 'small',
      variant = 'default',
      className,
      style,
    },
    ref,
  ) => {
    const renderContent = () => {
      const IconComponent = icon;

      return (
        <>
          {IconComponent && (
            <span className={styles.icon}>
              <IconComponent {...iconProps} size={size === 'large' ? 24 : 20} />
            </span>
          )}
          {label && <span className={styles.label}>{label}</span>}
        </>
      );
    };

    return link ? (
      <Link
        {...normalizeLinkProps(link)}
        className={classNames(
          styles.container,
          styles[size],
          styles[variant],
          { [styles.active]: active },
          className ?? '',
        )}
      >
        {renderContent()}
      </Link>
    ) : (
      <button
        ref={ref}
        onClick={onClick}
        disabled={disabled}
        style={style}
        className={classNames(
          styles.container,
          styles[size],
          styles[variant],
          { [styles.active]: active },
          className ?? '',
        )}
      >
        {isLoading ? (
          <>
            <span className={styles.hiddenContent}>{renderContent()}</span>
            <ClipLoader
              color={getCssVariable(cssVariablesByType(variant))}
              cssOverride={{
                borderWidth: '2px',
              }}
              size={size === 'small' ? 20 : 24}
              speedMultiplier={0.8}
              className={styles.loader}
            />
          </>
        ) : (
          renderContent()
        )}
      </button>
    );
  },
);

Button.displayName = 'Button';

export default Button;
