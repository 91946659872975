import { useEffect, useState } from 'react';

import { FirebaseError } from '@firebase/app';
import { checkActionCode, getAuth } from '@firebase/auth';


export default function useValidateActionCode({ oob }: { oob: string }) {
  const auth = getAuth();
  const [data, setdata] = useState<{
    email?: string | null;
    previousEmail?: string | null;
    operation:
      | 'EMAIL_SIGNIN'
      | 'PASSWORD_RESET'
      | 'RECOVER_EMAIL'
      | 'REVERT_SECOND_FACTOR_ADDITION'
      | 'VERIFY_AND_CHANGE_EMAIL'
      | 'VERIFY_EMAIL';
  } | null>(null);
  const [error, setError] = useState<Error | FirebaseError>();
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFetched, setIsFetched] = useState<boolean>(false);
  const [oobValidated, setOobValidated] = useState<boolean>(false);

  useEffect(() => {
    if (!isFetched && !isLoading && oob) {
      setIsLoading(true);
      checkActionCode(auth, oob)
        .then(({ data: { email, previousEmail }, operation }) => {
          setdata({
            email,
            previousEmail,
            operation,
          });
          setOobValidated(true);
        })
        .catch((e) => {
          setError(e);
          setIsError(true);
          setdata(null);
          setOobValidated(false);
        })
        .finally(() => {
          setIsFetched(true);
          setIsLoading(false);
        });
    }
  }, [auth, isFetched, isLoading, oob]);

  return {
    data,
    error,
    isError,
    isLoading,
    isFetched,
    oobValidated,
  };
}
