import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { FirebaseError } from '@firebase/app';
import {
  ActionCodeOperation,
  EmailAuthProvider,
  getAuth,
  reauthenticateWithCredential,
} from '@firebase/auth';
import { IconCircleXFilled, IconMailFilled } from '@tabler/icons-react';
import { t } from 'i18next';
import queryString from 'query-string';
import { Trans } from 'react-i18next';
import { useNavigate } from 'react-router';
import { ClipLoader } from 'react-spinners';

import { UserContext } from '@/contexts/UserContext';
import useChangeUserEmail from '@/hooks/firebase/useChangeUserEmail';
import useValidateActionCode from '@/hooks/firebase/useValidateActionCode';
import { ANALYTICS_EVENTS, useAnalytics } from '@/hooks/utils/useAnalytics';
import { getCssVariable } from '@/services/helpers';
import { ROUTES } from '@/types/routes';

import { Error403 } from '@/screens/ErrorScreen/403';

import BoxMessage from '@/components/BoxMessage';
import Button from '@/components/Button';
import ErrorBlock from '@/components/ErrorBlock';
import Input from '@/components/Input';
import Layout from '@/components/Layout';
import LogoHeader from '@/components/LogoHeader';

import styles from './styles.module.css';
import stylesSignIn from '../../SignIn/styles.module.css';

const successColor = getCssVariable('--icon-success');

export default function VerifyAndChangeEmailScreen() {
  const { oobCode } = useMemo(() => queryString.parse(location.search), []);

  const { isLoggedIn, workspaceId } = useContext(UserContext);

  const navigate = useNavigate();

  const { trackEvent } = useAnalytics();

  const { data, error, isError, isLoading, oobValidated } =
    useValidateActionCode({
      oob: oobCode as string,
    });

  const [pwd, setPwd] = useState<string>('');

  const auth = getAuth();

  const onUpdateEmail = useCallback(() => {
    if (data?.operation === ActionCodeOperation.VERIFY_AND_CHANGE_EMAIL)
      trackEvent(ANALYTICS_EVENTS.EMAIL_CHANGED, workspaceId ?? '');
    else if (data?.operation === ActionCodeOperation.RECOVER_EMAIL)
      trackEvent(ANALYTICS_EVENTS.EMAIL_RESTORED, workspaceId ?? '');
    setMailUpdated(true);
  }, [data?.operation, trackEvent, workspaceId]);

  const {
    mutate: updateEmail,
    isLoading: mutationIsLoading,
    isSuccess,
    error: mutationError,
    isError: mutationHasError,
  } = useChangeUserEmail();

  const [mailUpdated, setMailUpdated] = useState<boolean>(false);

  const canUpdateEmail = useMemo<boolean>(() => {
    return !!(
      oobValidated &&
      !mailUpdated &&
      isLoggedIn &&
      auth.currentUser?.email &&
      (data?.operation === ActionCodeOperation.VERIFY_AND_CHANGE_EMAIL ||
        data?.operation === ActionCodeOperation.RECOVER_EMAIL) &&
      data.email &&
      data.previousEmail === auth.currentUser.email
    );
  }, [
    auth.currentUser?.email,
    data?.email,
    data?.operation,
    data?.previousEmail,
    isLoggedIn,
    mailUpdated,
    oobValidated,
  ]);

  const reauthenticateAndUpdateEmail = useCallback(() => {
    if (canUpdateEmail && auth.currentUser?.email && pwd && oobCode) {
      reauthenticateWithCredential(
        auth.currentUser,
        EmailAuthProvider.credential(auth.currentUser.email, pwd),
      )
        .then(() => {
          updateEmail(
            {
              oobCode: oobCode as string,
              newEmail: data?.email as string,
            },
            { onSuccess: onUpdateEmail },
          );
        })
        .catch((error) => console.error(error));
    }
  }, [
    auth.currentUser,
    canUpdateEmail,
    data?.email,
    onUpdateEmail,
    oobCode,
    pwd,
    updateEmail,
  ]);

  useEffect(() => {
    if (canUpdateEmail && data?.email)
      updateEmail(
        { oobCode: oobCode as string, newEmail: data.email },
        { onSuccess: onUpdateEmail },
      );
  }, [canUpdateEmail, data?.email, onUpdateEmail, oobCode, updateEmail]);

  const showLoader = useMemo(() => {
    return (
      isLoggedIn &&
      !mailUpdated &&
      (isLoading || mutationIsLoading) &&
      !isError &&
      !mutationHasError
    );
  }, [
    isError,
    isLoading,
    isLoggedIn,
    mailUpdated,
    mutationHasError,
    mutationIsLoading,
  ]);

  const renderValidationError = useCallback(() => {
    if (isError) {
      if (error instanceof FirebaseError) {
        switch (error.code) {
          case 'auth/invalid-action-code':
            return (
              <ErrorBlock
                Icon={IconCircleXFilled}
                title={t('errors:generic.title')}
                message={t(`errors:firebase.${(error as FirebaseError).code}`)}
              />
            );
        }
      }
      return (
        <BoxMessage variant="error">{(error as Error).toString()}</BoxMessage>
      );
    }
  }, [error, isError]);

  const renderChangeEmailError = useCallback(() => {
    if (mutationHasError && mutationError instanceof FirebaseError) {
      switch (mutationError.code) {
        case 'auth/requires-recent-login':
          return (
            <div className={styles.askEmailContainer}>
              <h4>
                {t('common:verifyAndChangeEmail.requireRecentLogin.title')}
              </h4>
              <div role="form">
                <Input
                  label={t(
                    'common:verifyAndChangeEmail.requireRecentLogin.changeEmail.input.label',
                  )}
                  placeholder={t(
                    'common:verifyAndChangeEmail.requireRecentLogin.changeEmail.input.placeholder',
                  )}
                  name="password"
                  type="password"
                  value={pwd}
                  onChange={(e) => setPwd(e.target.value)}
                />
              </div>

              <div>
                <Button
                  label={t('common:submit')}
                  size="medium"
                  onClick={reauthenticateAndUpdateEmail}
                />
              </div>
            </div>
          );
        default:
          return (
            <ErrorBlock
              Icon={IconCircleXFilled}
              title={t('errors:generic.title')}
              message={t(
                `errors:firebase.${(mutationError as FirebaseError).code}`,
              )}
            />
          );
      }
    }
    return (
      <BoxMessage variant="error">
        {(mutationError as Error).toString()}
      </BoxMessage>
    );
  }, [mutationError, mutationHasError, pwd, reauthenticateAndUpdateEmail]);

  if (
    !canUpdateEmail &&
    data?.previousEmail &&
    auth.currentUser?.email &&
    data.previousEmail !== auth.currentUser.email
  )
    return (
      <Error403
        onClearError={() => {
          navigate(ROUTES.SIGN_IN, {
            replace: true,
            preventScrollReset: false,
          });
        }}
      />
    );
  else
    return (
      <Layout>
        <div className={stylesSignIn.container}>
          <div className={stylesSignIn.header}>
            <div className={stylesSignIn.left}>
              <LogoHeader />
            </div>
            <div className={stylesSignIn.right}>
              <Button
                label={t('forms:signIn.submit')}
                variant="outline"
                size="large"
                link={{ to: ROUTES.SIGN_IN, preventScrollReset: false }}
              />
            </div>
          </div>
          <div className={stylesSignIn.wrapper}>
            {showLoader ? (
              <ClipLoader />
            ) : (
              <div className={styles.form}>
                {oobValidated && !mailUpdated && !isLoggedIn && (
                  <ErrorBlock
                    Icon={IconCircleXFilled}
                    title={'Log in required'}
                    message={'You have to be logged in to change your email'}
                  />
                )}
                {!canUpdateEmail &&
                  data?.previousEmail &&
                  auth.currentUser?.email &&
                  data.previousEmail !== auth.currentUser.email && (
                    <ErrorBlock
                      Icon={IconCircleXFilled}
                      title={t('errors:generic.title')}
                      message={'You are not allowed to change this email'}
                    />
                  )}
                {isSuccess && !!data && (
                  <>
                    <div className={styles.successBox}>
                      <IconMailFilled size={40} color={successColor} />
                      <div className={styles.messageContainer}>
                        <h4>
                          {t('common:verifyAndChangeEmail.success.title', {
                            action:
                              data.operation ===
                              ActionCodeOperation.VERIFY_AND_CHANGE_EMAIL
                                ? 'changed'
                                : 'recovered',
                          })}
                        </h4>
                        <p>
                          <Trans
                            ns="common"
                            i18nKey="verifyAndChangeEmail.success.description"
                            values={{ email: data.email }}
                            components={{ strong: <strong /> }}
                          />
                        </p>
                      </div>
                    </div>
                    <div className={styles.successButtonContainer}>
                      <Button
                        label={t('common:backToLogin')}
                        variant="outline"
                        size="large"
                        link={{ to: ROUTES.SIGN_IN, preventScrollReset: false }}
                      />
                    </div>
                  </>
                )}
                {mutationHasError && renderChangeEmailError()}
                {isError && renderValidationError()}
              </div>
            )}
          </div>
        </div>
      </Layout>
    );
}
