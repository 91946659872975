import {
  forwardRef,
  KeyboardEventHandler,
  useCallback,
  useContext,
  useImperativeHandle,
  useState,
} from 'react';

import {
  EmailAuthProvider,
  getAuth,
  reauthenticateWithCredential,
} from '@firebase/auth';
import { t } from 'i18next';
import { Trans } from 'react-i18next';

import { UserContext } from '@/contexts/UserContext';

import Input from '@/components/Input';

import styles from './styles.module.css';
import { ChangeEmailStageProps, ChangeEmailStageRefProps } from './type';

const PasswordStage = forwardRef<
  ChangeEmailStageRefProps<string>,
  ChangeEmailStageProps
>(({ onSubmitting, onSubmitSuccess, onSubmitError }, ref) => {
  const { account } = useContext(UserContext);
  const auth = getAuth();
  const [pwd, setPwd] = useState<string>('');
  const [isValidationPassword, setIsValidationPassword] =
    useState<boolean>(false);

  const handleSubmit = useCallback(() => {
    if (auth.currentUser?.email) {
      onSubmitting?.();
      setIsValidationPassword(true);
      reauthenticateWithCredential(
        auth.currentUser,
        EmailAuthProvider.credential(auth.currentUser.email, pwd),
      )
        .then(() => onSubmitSuccess?.())
        .catch((error) => onSubmitError?.(error))
        .finally(() => setIsValidationPassword(false));
    }
  }, [auth.currentUser, onSubmitError, onSubmitSuccess, onSubmitting, pwd]);

  useImperativeHandle(
    ref,
    () => ({
      submit: handleSubmit,
      getValues: () => ({ data: pwd }),
    }),
    [handleSubmit, pwd],
  );

  const handleEnterKey: KeyboardEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        e.stopPropagation();
        e.preventDefault();
        handleSubmit();
      }
    },
    [handleSubmit],
  );

  return (
    <>
      <p className={styles.stageDescription}>
        <Trans
          ns="common"
          i18nKey={'modals.changeEmail.passwordStage.description'}
          values={{ email: account?.email }}
          components={{ strong: <strong /> }}
        />
      </p>
      <div role="form">
        <Input
          label={t('common:modals.changeEmail.passwordStage.input.label')}
          placeholder={t(
            'common:modals.changeEmail.passwordStage.input.placeholder',
          )}
          name="password"
          type="password"
          onKeyDown={handleEnterKey}
          disabled={isValidationPassword}
          value={pwd}
          onChange={(e) => setPwd(e.target.value)}
        />
      </div>
    </>
  );
});
PasswordStage.displayName = 'PasswordStage';

export default PasswordStage;
