import { useCallback, useContext, useEffect, useState } from 'react';

import { getAuth, sendEmailVerification } from '@firebase/auth';
import { t } from 'i18next';
import { useNavigate } from 'react-router';

import { NotificationsContext } from '@/contexts/NotificationContext';
import { UserContext } from '@/contexts/UserContext';
import { ROUTES } from '@/types/routes';

import Button from '@/components/Button';
import CheckYourInbox from '@/components/CheckInbox';

import styles from './styles.module.css';

export default function EmailVerificationScreen() {
  const { isLoggedIn, isUserEmailVerified, user } = useContext(UserContext);
  const navigate = useNavigate();
  const auth = getAuth();
  const [isLoading, setIsLoading] = useState(false);
  const { addNotification } = useContext(NotificationsContext);

  const sendVerificationEmail = useCallback(async () => {
    if (auth.currentUser) {
      setIsLoading(true);
      try {
        await sendEmailVerification(auth.currentUser);
      } catch (error) {
        setIsLoading(false);
        addNotification({
          type: 'error',
          title: t('errors:generic.title') as string,
          description: t('errors:generic.description') as string,
        });
      }
    }
  }, [addNotification, auth.currentUser]);

  useEffect(() => {
    if (!isLoggedIn || !user) {
      navigate(ROUTES.SIGN_IN, {
        preventScrollReset: false,
        replace: true,
      });
      return;
    }

    if (isUserEmailVerified) {
      navigate(ROUTES.ROOT, {
        preventScrollReset: false,
        replace: true,
      });
      return;
    }
  }, [isLoggedIn, isUserEmailVerified, navigate, user]);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <CheckYourInbox
          iconSize={64}
          title="Verify your email address"
          message={`Check your ${user?.email} inbox for an email verification link`}
          footer={
            <Button
              label={t('common:resendVerificationEmail')}
              isLoading={isLoading}
              variant="ghost"
              size="large"
              onClick={sendVerificationEmail}
            />
          }
        />
      </div>
    </div>
  );
}
