import {
  forwardRef,
  KeyboardEventHandler,
  useCallback,
  useContext,
  useImperativeHandle,
  useState,
} from 'react';

import { getAuth, verifyBeforeUpdateEmail } from '@firebase/auth';
import { t } from 'i18next';
import { Trans } from 'react-i18next';

import { UserContext } from '@/contexts/UserContext';
import useValidateEmail from '@/hooks/account/useValidateEmail';

import Input from '@/components/Input';

import styles from './styles.module.css';
import { ChangeEmailStageProps, ChangeEmailStageRefProps } from './type';

const EmailStage = forwardRef<
  ChangeEmailStageRefProps<string>,
  ChangeEmailStageProps
>(({ onSubmitting, onSubmitSuccess, onSubmitError }, ref) => {
  const { account } = useContext(UserContext);
  const auth = getAuth();
  const [newEmail, setNewEmail] = useState<string>('');
  const [isValidationEmail, setIsValidationEmail] = useState<boolean>(false);

  const { validate } = useValidateEmail({
    email: newEmail,
  });

  const handleSubmit = useCallback(() => {
    if (auth.currentUser) {
      setIsValidationEmail(true);
      onSubmitting?.();
      validate()
        .then((data) => {
          if (!data?.result) throw new Error(t('errors:email.invalid'));
        })
        .then(
          () =>
            auth.currentUser &&
            verifyBeforeUpdateEmail(auth.currentUser, newEmail),
        )
        .then(() => onSubmitSuccess?.())
        .catch((error) => onSubmitError?.(error))
        .finally(() => setIsValidationEmail(false));
    }
  }, [
    auth.currentUser,
    newEmail,
    onSubmitError,
    onSubmitSuccess,
    onSubmitting,
    validate,
  ]);

  useImperativeHandle(
    ref,
    () => ({
      submit: handleSubmit,
      getValues: () => ({ data: newEmail }),
    }),
    [handleSubmit, newEmail],
  );

  const handleEnterKey: KeyboardEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        e.stopPropagation();
        e.preventDefault();
        handleSubmit();
      }
    },
    [handleSubmit],
  );

  return (
    <>
      <p className={styles.stageDescription}>
        <Trans
          ns="common"
          i18nKey={'modals.changeEmail.emailStage.description'}
          values={{ email: account?.email }}
          components={{ strong: <strong /> }}
        />
      </p>
      <div role="form">
        <Input
          label={t('common:modals.changeEmail.emailStage.input.label')}
          placeholder={t(
            'common:modals.changeEmail.emailStage.input.placeholder',
          )}
          name="email"
          disabled={isValidationEmail}
          value={newEmail}
          onKeyDown={handleEnterKey}
          onChange={(e) => setNewEmail(e.target.value)}
        />
      </div>
    </>
  );
});
EmailStage.displayName = 'EmailStep';

export default EmailStage;
