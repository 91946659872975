import { BaseResponse } from '@/types/base-responses';
import { USER_ROLE, WORKSPACE_MEMBER_PERMISSION } from '@/types/enums';
import { Dto, TResponse } from '@/types/generic';
import {
  TAccountConsent,
  TInvitationInfo,
  TUserAccount,
  TUserAccountCreation,
} from '@/types/workspace';

import {
  normalizeInvitationInfo,
  normalizeUserAccount,
  normalizeUserConsent,
} from '../normalizer';

import { http } from '.';

/**
 * This asynchronous function retrieves the account details of the currently authenticated user.
 *
 * @returns {Promise<TUserAccount | undefined>} - A promise that resolves to a TUserAccount object representing the user's account details, or undefined if an error occurs.
 *
 * @throws Will call the errorHandler function if the request fails.
 */

export async function getAccount() {
  const {
    data: { data },
  }: { data: TResponse<TUserAccount> } = await http.get(`/accounts/me`);
  return normalizeUserAccount(data);
}

/**
 * This asynchronous function accepts the consent form for the currently authenticated user.
 *
 * @param {Object} params - The parameters for the function.
 * @param {string} params.html - The HTML content of the consent form.
 *
 * @throws Will call the errorHandler function if the request fails.
 */

export async function acceptConsent({ html }: { html: string }) {
  return http.put(`/accounts/consent`, {
    consentTemplate: html,
  });
}

/**
 * This asynchronous function verifies the consent of the currently authenticated user.
 *
 * @throws Will call the errorHandler function if the request fails.
 */

export async function verifyConsent() {
  return http.put(`/accounts/consent/verify`);
}

/**
 * This asynchronous function updates the account details of the currently authenticated user.
 *
 * @param {Object} accountData - The new account data. The 'workspaces' and 'image' fields are omitted.
 *
 * @returns {Promise<TUserAccount | undefined>} - A promise that resolves to a TUserAccount object representing the updated account details, or undefined if an error occurs.
 *
 * @throws Will call the errorHandler function if the request fails.
 */
export async function updateAccount(
  accountData: Partial<Omit<TUserAccount, 'workspaces' | 'image'>>,
) {
  const account: TUserAccount = await http.put(`/accounts/me`, accountData);
  return normalizeUserAccount(account);
}

/**
 * This asynchronous function updates the account settings of the currently authenticated user.
 *
 * @param {Object} accountData - The new account settings. Only the 'weeklyEmail' field is included.
 *
 * @returns {Promise<TUserAccount | undefined>} - A promise that resolves to a TUserAccount object representing the updated account settings, or undefined if an error occurs.
 *
 * @throws Will call the errorHandler function if the request fails.
 */

export async function updateAccountSettings(accountData: {
  weeklyEmail: boolean;
  workspaceId: string;
}) {
  const account: TUserAccount = await http.put(
    `/accounts/me/settings`,
    accountData,
  );
  return normalizeUserAccount(account);
}

export async function updateAccountRole({
  workspaceId,
  accountId,
  role,
}: {
  workspaceId: string;
  role: USER_ROLE;
  accountId: string;
}) {
  return http.put(`/accounts/${accountId}/role`, {
    workspaceId,
    role,
  });
}
export async function updateAccountPermission({
  workspaceId,
  accountId,
  permission,
}: {
  workspaceId: string;
  permission: WORKSPACE_MEMBER_PERMISSION;
  accountId: string;
  invited?: boolean;
}) {
  return http.put(`/accounts/${accountId}/permission`, {
    workspaceId,
    permission,
  });
}

/**
 * This asynchronous function updates the account image of the currently authenticated user.
 *
 * @param {Object} fileAttachmentDto - An object containing the file path of the new image.
 *
 * @returns {Promise<TUserAccount | undefined>} - A promise that resolves to a TUserAccount object representing the updated account image, or undefined if an error occurs.
 *
 * @throws Will call the errorHandler function if the request fails.
 */

export async function updateAccountImage(fileAttachmentDto: {
  filePath: string;
}) {
  const account: TUserAccount = await http.put(
    `/accounts/me/image/`,
    fileAttachmentDto,
  );
  return normalizeUserAccount(account);
}

/**
 * This asynchronous function deletes the account image of the currently authenticated user.
 *
 * @returns {Promise<TUserAccount | undefined>} - A promise that resolves to a TUserAccount object representing the account details without the image, or undefined if an error occurs.
 *
 * @throws Will call the errorHandler function if the request fails.
 */

export async function deleteAccountImage() {
  const account: TUserAccount = await http.delete(`/accounts/me/image/`);
  return normalizeUserAccount(account);
}

/**
 * This asynchronous function retrieves the invitation information associated with a specific token.
 *
 * @param {string} tk - The token associated with the invitation.
 *
 * @returns {Promise<TInvitationInfo>} The invitation information if the request is successful.
 *
 * @throws Will call the errorHandler function if the request fails.
 */

export async function getInvitationInfo(tk: string) {
  const {
    data: { data },
  }: {
    data: TResponse<Dto<TInvitationInfo>>;
  } = await http.get(`/accounts/invitation-info?tk=${tk}`);
  return normalizeInvitationInfo(data);
}

/**
 * This asynchronous function accepts an invitation associated with a specific token.
 *
 * @param {string} tk - The token associated with the invitation.
 *
 * @throws Will call the errorHandler function if the request fails.
 */

export async function acceptInvitation(tk: string) {
  const {
    data: { data },
  }: { data: TResponse<TUserAccount & { newWorkspace: string }> } =
    await http.post(`/accounts/accept-invitation?tk=${tk}`);

  return {
    ...normalizeUserAccount(data),
    newWorkspace: data.newWorkspace,
  };
}

/**
 * This asynchronous function creates a new user account.
 *
 * @param {Object} createAccount - The parameters for the function.
 * @param {string} createAccount.token - The token associated with the account creation.
 * @param {Omit<Required<TUserAccountCreation & { token: string }>,'password'>} createAccount - The details of the account to be created.
 *
 * @returns {Promise<TUserAccount>} The details of the created account if the request is successful.
 *
 * @throws Will call the errorHandler function if the request fails.
 */

export async function createAccount(
  createAccount: Omit<
    Required<TUserAccountCreation & { token: string }>,
    'password'
  >,
) {
  const {
    data: { data },
  }: { data: TResponse<TUserAccount> } = await http.post(
    `/accounts/new${createAccount?.token ? `?tk=${createAccount?.token}` : ''}`,
    { ...createAccount },
  );

  return data;
}

export async function getAcountConsent() {
  const {
    data: { data },
  } = await http.get<BaseResponse<TAccountConsent>>(`/accounts/me/consent`);
  return data
    ? normalizeUserConsent(data)
    : ({ mustShow: false } as TAccountConsent);
}
