import {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';

import { FirebaseError } from '@firebase/app';
import {
  confirmPasswordReset,
  getAuth,
  verifyPasswordResetCode,
} from '@firebase/auth';
import { IconCircleXFilled, IconShieldCheckFilled } from '@tabler/icons-react';
import { useQueryClient } from '@tanstack/react-query';
import { t } from 'i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { NotificationsContext } from '@/contexts/NotificationContext';
import { UserContext } from '@/contexts/UserContext';
import { ANALYTICS_EVENTS, useAnalytics } from '@/hooks/utils/useAnalytics';
import { checkPasswordValidity, getCssVariable } from '@/services/helpers';
import { ROUTES } from '@/types/routes';

import BoxMessage from '@/components/BoxMessage';
import Button from '@/components/Button';
import Layout from '@/components/Layout';
import LogoHeader from '@/components/LogoHeader';
import type { FormData } from '@/components/ResetPasswordForm';
import ResetPasswordForm from '@/components/ResetPasswordForm';

import styles from './styles.module.css';
import stylesSignIn from '../../SignIn/styles.module.css';

const successColor = getCssVariable('--icon-success');
const errorColor = getCssVariable('--icon-critical');

export default function ResetPasswordScreen() {
  const location = useLocation();
  const oobCode = location?.state?.oobCode;
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const [success, setSuccess] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [_passwordCheck, setPasswordCheck] = useState<Record<
    string,
    boolean
  > | null>(null);
  const navigate = useNavigate();

  const onSuccess = useCallback(() => {
    setSuccess(true);
    setSubmitted(true);
  }, [setSuccess]);

  useEffect(() => {
    setError('');
  }, [currentPassword]);

  const auth = getAuth();
  const { isLoggedIn, clearLocalStorage, workspace } = useContext(UserContext);

  useEffect(() => {
    if (currentPassword) {
      setPasswordCheck(checkPasswordValidity(currentPassword));
    } else {
      setPasswordCheck(null);
    }
  }, [currentPassword, setPasswordCheck]);

  const { addNotification } = useContext(NotificationsContext);

  const [oobCheckedStatus, setOobCheckedStatus] = useState<
    'checking' | 'complete' | undefined
  >();

  const [oobValid, setOobValid] = useState(false);
  const { trackEvent } = useAnalytics();

  useLayoutEffect(() => {
    if (!oobCode)
      return navigate(ROUTES.SIGN_IN, { preventScrollReset: false });
    else if (!oobCheckedStatus) {
      setOobCheckedStatus('checking');
      verifyPasswordResetCode(auth, oobCode)
        .then(() => setOobValid(true))
        .catch((err) => {
          if (
            err instanceof FirebaseError &&
            err.code === 'auth/invalid-action-code'
          ) {
            setOobValid(false);
            addNotification({
              type: 'error',
              title: 'Invalid action code',
              description:
                'The action code is invalid. This can happen if the code is malformed, expired, or has already been used.',
            });
          }
        })
        .finally(() => setOobCheckedStatus('complete'));
    }
  }, [addNotification, auth, navigate, oobCheckedStatus, oobCode]);

  const queryClient = useQueryClient();

  const onLogoutFn = useCallback(async () => {
    await auth?.signOut();
    queryClient.clear();
    clearLocalStorage();
  }, [auth, clearLocalStorage, queryClient]);

  const onSubmitFn = useCallback(
    (data: FormData) => {
      setError('');

      setIsLoading(true);
      const onSubmit = async () => {
        try {
          await confirmPasswordReset(auth, oobCode, data.password);
          trackEvent(ANALYTICS_EVENTS.PASSWORD_CHANGED, workspace?.id ?? '');
          if (isLoggedIn) await onLogoutFn();
          onSuccess();
          setError('');
        } catch (error) {
          if (error instanceof FirebaseError) setError(error.code);
        } finally {
          setIsLoading(false);
        }
      };
      onSubmit();
    },
    [
      auth,
      oobCode,
      trackEvent,
      workspace?.id,
      isLoggedIn,
      onLogoutFn,
      onSuccess,
    ],
  );

  const [submitted, setSubmitted] = useState(false);
  const onDismissMessageError = useCallback(() => {
    setError('');
  }, []);

  if (!oobCheckedStatus || oobCheckedStatus === 'checking') return;
  else if (!oobValid) {
    return (
      <Layout>
        <div className={stylesSignIn.container}>
          <div className={stylesSignIn.header}>
            <div className={stylesSignIn.left}>
              <LogoHeader />
            </div>
          </div>
          <div className={stylesSignIn.wrapper}>
            <div className={stylesSignIn.form}>
              <div className={styles.messageContainer}>
                <div className={styles.successBox}>
                  <IconCircleXFilled size={40} color={errorColor} />
                  <div className={styles.messageContainer}>
                    <h4>Reset password link is invalid</h4>
                    <p>Link is malformed, expired, or has already been used.</p>
                  </div>
                  <div>
                    <Button
                      label={t('forms:resetPassword.success.submit') ?? ''}
                      size="large"
                      link={ROUTES.SIGN_IN}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  } else
    return (
      <Layout>
        <div className={stylesSignIn.container}>
          <div className={stylesSignIn.header}>
            <div className={stylesSignIn.left}>
              <LogoHeader />
            </div>
            <div className={stylesSignIn.right}>
              <Button
                label={t('forms:signUp.submit')}
                variant="outline"
                size="large"
                link={{ to: ROUTES.SIGN_UP, preventScrollReset: false }}
              />
            </div>
          </div>
          <div className={stylesSignIn.wrapper}>
            <div className={stylesSignIn.form}>
              {!submitted && (
                <h1 className={stylesSignIn.title}>
                  {t('forms:resetPassword.title')}
                </h1>
              )}
              {submitted && success && (
                <>
                  <div className={styles.successBox}>
                    <IconShieldCheckFilled size={40} color={successColor} />
                    <div className={styles.messageContainer}>
                      <h4>{t('forms:resetPassword.success.title')}</h4>
                      <p>{t('forms:resetPassword.success.message')}</p>
                    </div>
                  </div>
                  <div className={styles.buttonContainer}>
                    <Button
                      label={t('forms:resetPassword.success.submit') ?? ''}
                      size="large"
                      link={ROUTES.SIGN_IN}
                    />
                  </div>
                </>
              )}
              {error && (
                <div className={stylesSignIn.box}>
                  <BoxMessage variant="error" onDismiss={onDismissMessageError}>
                    {t(`errors:firebase.${error}`)}
                  </BoxMessage>
                </div>
              )}

              {!submitted && !success && (
                <ResetPasswordForm
                  onSubmitFn={onSubmitFn}
                  isLoading={isLoading}
                  onChangeFn={setCurrentPassword}
                />
              )}
            </div>
          </div>
        </div>
      </Layout>
    );
}
