import { IconInbox } from '@tabler/icons-react';

import { getCssVariable } from '@/services/helpers';

import styles from './styles.module.css';

type Props = {
  title: string;
  message: string;
  iconSize?: number;
  footer?: JSX.Element;
};

const iconColor = getCssVariable('--icon-subdued');
export default function CheckYourInbox({
  title,
  message,
  footer,
  iconSize = 40,
}: Props) {
  return (
    <>
      <div className={styles.successBox}>
        <IconInbox size={iconSize} color={iconColor} />
        <div className={styles.messageContainer}>
          <h4>{title}</h4>
          <p>{message}</p>
        </div>
      </div>
      {footer && <div className={styles.buttonContainer}>{footer}</div>}
    </>
  );
}
