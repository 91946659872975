import { useCallback, useContext, useEffect, useState } from 'react';

import ModalMobileExperience from '@components/Modals/ModalMobileExperience';
import ModalTermsOfService from '@components/Modals/ModalTermsOfService';
import { DndContext } from '@dnd-kit/core';
import {
  restrictToParentElement,
  restrictToVerticalAxis,
} from '@dnd-kit/modifiers';

import { useProfileModal } from '@/contexts/ModalPreferenceContext';
import { UserContext, useSubscription } from '@/contexts/UserContext';
import { ANALYTICS_PAGE_NAME, useAnalytics } from '@/hooks/utils/useAnalytics';
import useSubscriptionWSResponse from '@/hooks/utils/useSubscriptionWSResponse';

import Button from '@/components/Button';
import Drawer from '@/components/Drawer';
import Header from '@/components/Header';
import Layout from '@/components/Layout';
import ModalPastDueSubscription from '@/components/Modals/ModalPastDueSubscription';
import { ContentPages } from '@/components/Modals/ModalUserProfile';
import TTimelineProject from '@/components/Timelines/TimelineProjects';
import { TimelineProjectProvider } from '@/components/Timelines/TimelineProjects/context';
import WarningHeader from '@/components/WarningHeader';

import styles from './styles.module.css';
export default function DashboardProjectView() {
  const { trackPage } = useAnalytics();
  const { workspaceId } = useContext(UserContext);
  useEffect(() => {
    trackPage(ANALYTICS_PAGE_NAME.PROJECTS, workspaceId as string);
  }, [workspaceId, trackPage]);

  const { isPastDue } = useSubscription();

  const [isOpened, setIsOpened] = useState<boolean>(isPastDue);

  useEffect(() => {
    setIsOpened(isPastDue);
  }, [isPastDue]);

  useSubscriptionWSResponse();

  const { openModal } = useProfileModal();
  const openBillingModal = useCallback(() => {
    openModal(ContentPages.BILLING_INFO);
  }, [openModal]);

  const [isTermsOfServiceOpened, setIsTermsOfServiceOpened] = useState(false);

  const handleTermsOfServiceOpenChange = useCallback(
    (status: 'opened' | 'closed') => {
      setIsTermsOfServiceOpened(status === 'opened');
    },
    [],
  );

  return (
    <Layout>
      <TimelineProjectProvider>
        <WarningHeader isOpened={isPastDue}>
          <span>
            We were unable to process your last payment. Please{' '}
            <Button
              label="update your billing information"
              variant="link"
              size="small"
              className={styles.linkWarning}
              onClick={openBillingModal}
            />
          </span>
        </WarningHeader>
        <Header />
        <DndContext
          modifiers={[restrictToVerticalAxis, restrictToParentElement]}
        >
          <TTimelineProject />

          <Drawer />
        </DndContext>
        <ModalTermsOfService onOpenChange={handleTermsOfServiceOpenChange} />
        <ModalMobileExperience />
      </TimelineProjectProvider>
      {isOpened && !isTermsOfServiceOpened && (
        <ModalPastDueSubscription
          isOpen={true}
          onClose={() => {
            setIsOpened(false);
          }}
        />
      )}
    </Layout>
  );
}
