import { useEffect } from 'react';

import queryString from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';

import { ROUTES } from '@/types/routes';

import styles from './styles.module.css';

enum Mode {
  RESET_PASSWORD = 'resetPassword',
  VERIFY_EMAIL = 'verifyEmail',
  VERIFY_AND_CHANGE_EMAIL = 'verifyAndChangeEmail',
  RECOVER_EMAIL = 'recoverEmail',
}

export default function AuthRedirectScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const { mode, oobCode } = queryString.parse(location.search);
  useEffect(() => {
    // navigate to reset password screen if mode is resetPassword
    if (oobCode) {
      let route: ROUTES = ROUTES.ROOT;
      const queryParams = `mode=${mode}&oobCode=${oobCode}`;
      switch (mode as Mode) {
        case Mode.RESET_PASSWORD:
          route = ROUTES.PASSWORD_RESET;
          break;
        case Mode.VERIFY_EMAIL:
          route = ROUTES.VALIDATE_EMAIL;
          break;
        case Mode.VERIFY_AND_CHANGE_EMAIL:
          route = ROUTES.VALIDATE_AND_CHANGE_EMAIL;
          break;
        case Mode.RECOVER_EMAIL:
          route = ROUTES.RECOVER_EMAIL;
          break;
      }
      return navigate(`${route}?${queryParams}`, {
        preventScrollReset: false,
        state: { oobCode },
      });
    }
    // navigate to home if no mode or oobcode is found
    return navigate(ROUTES.ROOT, { preventScrollReset: false });
  }, [mode, navigate, oobCode]);

  return (
    <div className={styles['loading-container']}>
      <ClipLoader />
    </div>
  );
}
